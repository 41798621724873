import { Observable } from 'rxjs';
import { AppModule } from '../app.module';
import { ApiExceptions, ApiResponse, IApiObject, ITableData } from '../libs/interfaces';
import { LoadingService } from '../services/loading.service';

export abstract class Provider<Model> {
	protected readonly url: string = '/';
	private readonly loadingService = AppModule.injector.get(LoadingService);

	constructor() {}

	/**
	 * Process the request and only return the data portion of the request
	 *
	 * A framework function to add a loading service and internet listener
	 *
	 * @param request The Api request
	 * @param showLoading A parameter to show a loading indicator or not
	 * @returns A promise containing the result of the request as <Model>
	 */
	protected async process<M = Model>(
		request: Observable<IApiObject<M | Array<M> | ITableData<M>>>,
		showLoading: boolean = true
	): Promise<M | Array<M> | ITableData<M>> {
		return (await this.processRequest(request, showLoading))[0];
	}

	/**
	 * Process the request and return the data portion and exceptions of the request
	 *
	 * A framework function to add a loading service and internet listener
	 *
	 * @param request The Api request
	 * @param showLoading A parameter to show a loading indicator or not
	 * @returns A promise containing the result of the request as <Model>
	 */
	protected async processWithExceptions<M = Model>(
		request: Observable<IApiObject<M | Array<M> | ITableData<M>>>,
		showLoading: boolean = true
	): Promise<ApiResponse<M | Array<M> | ITableData<M>>> {
		return this.processRequest(request, showLoading);
	}

	/**
	 * Process the request
	 *
	 * A framework function to add a loading service and internet listener
	 *
	 * @param request The Api request
	 * @param showLoading A parameter to show a loading indicator or not
	 * @returns A promise containing the result of the request as {@link ApiResponse}
	 */
	private processRequest<M = Model>(
		request: Observable<IApiObject<M | Array<M> | ITableData<M>>>,
		showLoading: boolean = true
	): Promise<ApiResponse<M | Array<M> | ITableData<M>>> {
		if (true /* check for internet */) {
			return new Promise((resolve, reject) => {
				void this.loadingService
					.startLoading(showLoading)
					.then(() => {
						request.subscribe(
							(result) => {
								this.loadingService.stopLoading(showLoading);
								resolve([
									(result as IApiObject<M>).data,
									(result as IApiObject<M>).exceptions as ApiExceptions,
								]);
							},
							(error) => {
								this.loadingService.stopLoading(showLoading, error?.status === 0);
								reject(error);
							}
						);
					})
					.catch();
			});
		} else return Promise.resolve() as any;
	}
}
