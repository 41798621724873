import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { IApiObject, ITableData } from '../libs/interfaces';
import { AppManager } from '../managers/app.manager';
import { ErrorService } from '../services/error.service';

enum HTTP_METHOD {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
	SEARCH = 'SEARCH',
	DOWNLOAD = 'DOWNLOAD',
}

/**
 * A general API class that handles requests to the api
 */
@Injectable({
	providedIn: 'root',
})
export class Api {
	private readonly baseurl: string = ENV.BASE_URL;
	private readonly headers: { [header: string]: string | string[] };

	constructor(
		private readonly http: HttpClient,
		private readonly errorService: ErrorService,
		private readonly appManager: AppManager
	) {
		this.headers = {
			IfMatch: this.appManager.getVersion() as string,
		};
	}

	/**
	 * A GET request to the url
	 *
	 * @param url The specific url for the request
	 * @param hideError Whether to hide a possible error so it is not auto shown be the error service in the request method
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public get<Model>(
		url: string,
		hideError: boolean = false
	): Observable<IApiObject<Model | Array<Model> | ITableData<Model>>> {
		return this.request<Model>(HTTP_METHOD.GET, url, null, hideError);
	}

	/**
	 * A SEARCH request the url
	 *
	 * @param url The specific url for the request
	 * @param body The body for the SEARCH request (should be the same as the resulting object)
	 *  The search HTTP method is not supported by nest, so is adjusted to POST
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public search<Model>(
		url: string,
		body: Partial<Model>
	): Observable<IApiObject<Model | Array<Model> | ITableData<Model>>> {
		return this.request(HTTP_METHOD.POST, url, body);
	}

	/**
	 * A POST request the url
	 *
	 * @param url The specific url for the request
	 * @param body The body for the POST request
	 * @param hideError Whether to hide a possible error so it is not auto shown be the error service in the request method
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public post<Model>(url: string, body: Model, hideError: boolean = false): Observable<IApiObject<Model>> {
		return this.request(HTTP_METHOD.POST, url, body, hideError);
	}

	/**
	 * A PUT request the url
	 *
	 * @param url The specific url for the request
	 * @param body The body for the PUT request
	 * @param hideError Whether to hide a possible error so it is not auto shown be the error service in the request method
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public put<Model>(url: string, body: Partial<Model>, hideError: boolean = false): Observable<IApiObject<Model>> {
		return this.request(HTTP_METHOD.PUT, url, body, hideError);
	}

	/**
	 * A DELETE request the url
	 *
	 * @param url The specific url for the request
	 * @param body The request body
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public delete<Model>(url: string, body?: Partial<Model>): Observable<IApiObject> {
		return this.request(HTTP_METHOD.DELETE, url, body);
	}

	/**
	 * A specialist download method that receives a file or blob from the api
	 *
	 * @param url The api url
	 * @param filename The filename to give to the file when it is returned
	 * @param data The data for the download request when needed
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public download(url: string, filename: string, data: any = {}): Observable<any> {
		return new Observable((subscriber) => {
			this.http
				.post(this.baseurl + url, data, { headers: this.headers, responseType: 'blob', observe: 'response' })
				.pipe(
					map((res) => ({
						headers: res.headers,
						content: res.body,
					}))
				)
				.subscribe(
					(response) => {
						if (!/application\/json/.test(response.headers.get('Content-Type') as string)) {
							saveAs(response.content as Blob, filename);
							subscriber.next(response.content);
						} else {
							void this.errorService.showApiError(new Error('Invalid file format')).catch();
							subscriber.error();
						}
					},
					(error) => {
						const fileReader = new FileReader();
						fileReader.onload = () => {
							error.message = JSON.parse(fileReader.result as string).message;
							void this.errorService.showApiError(error).catch();
							subscriber.error();
						};
						fileReader.readAsText(error.error);
					},
					() => subscriber.complete()
				);
		});
	}

	/**
	 * A general request handler
	 *
	 * @param method The HTTP method
	 * @param url The api url
	 * @param body The request body when needed (for POST, PUT and SEARCH requests)
	 * @param hideError Whether or not to hide the error
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	private request<Model>(
		method: HTTP_METHOD,
		url: string,
		body?: any,
		hideError: boolean = false
	): Observable<IApiObject<any>> {
		return new Observable((subscriber) => {
			this.http.request(method, this.baseurl + url, { body: body, headers: this.headers }).subscribe(
				(result) => {
					subscriber.next({
						source: 'API',
						data: (result as IApiObject<Model>).data,
						exceptions: (result as IApiObject<Model>).exceptions,
					});
				},
				(error) => {
					if (!hideError) {
						void this.errorService.showApiError(error).catch();
					}
					subscriber.error(error);
				},
				() => subscriber.complete()
			);
		});
	}
}
