import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserManager } from '../managers/user.manager';
import { AuthenticationService } from '../services/authentication.service';

/**
 * A guard that checks if the user is authenticated
 *
 * @see {@link AuthenticationService#isAuthenticated}
 */
@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		public readonly router: Router,
		public readonly authenticationService: AuthenticationService,
		public readonly userManager: UserManager
	) {}

	/**
	 * Overridden Angular function that contains guard logic and handles when it's not compliant
	 *
	 * @description It checks whether the user is authenticated or not and checks the role of the user against the ACL
	 * @param {ActivatedRouteSnapshot} route The activated route
	 * @param {RouterStateSnapshot} state The router
	 * @returns {Observable<boolean>} Whether the user is authenticated or not in an Observable
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return new Observable((subscriber) => {
			this.authenticationService
				.isAuthenticated()
				.then((isAuthenticated) => {
					if (isAuthenticated) {
						subscriber.next(true);
					} else {
						void this.router.navigate(['/login']).catch();
						subscriber.next(false);
					}
					subscriber.complete();
				})
				.catch(() => {
					void this.router.navigate(['/login']).catch();
					subscriber.next(false);
					subscriber.complete();
				});
		});
	}
}
