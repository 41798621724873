import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Environment } from '../libs/enums';
import { StorageService } from '../services/storage.service';

/* TODO: replace managers with redux strategy? */

/**
 * The general manager for the application. A Manager is responsible for the state and reusability (accross the application) of certain properties
 */
@Injectable({
	providedIn: 'root',
})
export class AppManager {
	/**
	 * Property that stores whether the titles of the navigation is shown.
	 * It comes down to the state of the menu.
	 */
	private menuIsOpen: boolean = true;

	constructor(private readonly storageService: StorageService, private readonly meta: Meta) {}

	/**
	 * Get the current version of the application, defined in package.json
	 *
	 * @returns {string} The version of the application
	 */
	public getVersion(): string | undefined {
		return this.meta.getTag('name=version')?.content;
	}

	/**
	 * Get the current app environment
	 *
	 * @returns {Environment} The current app environment
	 */
	public getEnvironment(): Environment {
		if (location.href.includes('localhost')) {
			return Environment.Local;
		} else if (location.href.includes('staging')) {
			return Environment.Staging;
		} else if (location.href.includes('acceptance')) {
			return Environment.Acceptance;
		}
		return Environment.Production;
	}

	/**
	 * Get whether the app is not in a production environment
	 *
	 * @returns {boolean} Whether the app is not in production or in production
	 */
	public isNotProduction(): boolean {
		return this.getEnvironment() !== Environment.Production;
	}
}
