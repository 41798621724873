import { Injectable } from '@angular/core';
import { Institution, Role, StorageKey } from '../libs/enums';
import { IUser } from '../models/user.model';
import { StorageService } from '../services/storage.service';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user object. A Manager is responsible for the state and reusability (across the application) of certain properties
 *
 * @see {@link IUser}
 */
@Injectable({
	providedIn: 'root',
})
export class UserManager {
	private user!: IUser | null;
	private token!: string | null;
	private refreshToken!: string | null;

	constructor(private readonly storageService: StorageService) {}

	/**
	 * Set the user object in session storage
	 *
	 * @param user The user that is set
	 */
	public setUser(user: IUser): void {
		this.user = user;
	}

	/**
	 * Save the user currently in session storage to persistent storage
	 *
	 * @param user The user
	 */
	public saveUser(user?: IUser): void {
		if (user) {
			this.user = user;
		}
		if (this.user) {
			this.storageService.setItem(StorageKey.user, this.user);
		}
	}

	/**
	 * Get the user, firstly from session storage and secondly from persistent storage
	 *
	 * @returns A {@link IUser}
	 */
	public getUser(): IUser {
		if (!this.user) {
			this.user = this.storageService.getItem<IUser>(StorageKey.user);
		}
		return this.user;
	}

	/**
	 * Remove the user from session and persistent storage
	 */
	public removeUser(): void {
		this.user = null;
		this.token = null;
		this.refreshToken = null;
		this.storageService.removeItem(StorageKey.user);
		this.storageService.removeItem(StorageKey.token);
		this.storageService.removeItem(StorageKey.refreshToken);
	}

	/**
	 * Get the api token of the current user.
	 * It is used as authorization method for api requests.
	 *
	 * @returns The api token
	 */
	public getToken(): string | null {
		return this.token;
	}

	/**
	 * Set the token of the current user in session storage
	 *
	 * @param token A new token for the user
	 */
	public setToken(token: string): void {
		this.token = token;
	}

	/**
	 * Set the token of the current user in persistent storage
	 *
	 * @param token A new token for the user
	 */
	public saveToken(token?: string): void {
		if (token) {
			this.token = token;
		}
		if (this.token) {
			this.storageService.setItem(StorageKey.token, this.token);
		}
	}

	/**
	 * Get the refresh token of the current user.
	 * It is used to get a new token when it is expired
	 *
	 * @returns The refresh token
	 */
	public getRefreshToken(): string | null {
		return this.refreshToken;
	}

	/**
	 * Set the refresh token of the current user in session storage
	 * It is used to get a new token when it is expired
	 *
	 * @param token The refresh token
	 */
	public setRefreshToken(token: string): void {
		this.refreshToken = token;
	}

	/**
	 * Set the refresh token of the current user in persistent storage
	 *
	 * @param token The refresh token
	 */
	public saveRefreshToken(token?: string): void {
		if (token) {
			this.refreshToken = token;
		}
		if (this.refreshToken) {
			this.storageService.setItem(StorageKey.refreshToken, this.refreshToken);
		}
	}

	/**
	 * Get the user id of the current user
	 *
	 * @returns The user id
	 */
	public getUserId(): string | undefined {
		return this.user?.sub;
	}

	/**
	 * Get the user email of the current user
	 *
	 * @returns The user email
	 */
	public getUserEmail(): string | undefined {
		return this.user?.email;
	}

	/**
	 * Get the user email of the current user
	 *
	 * @returns The user email
	 */
	public getUserInstitution(): Institution | undefined {
		return this.user?.institution;
	}

	/* public getUserDetailsId(): string {
		switch(this.user?.role) {
			case Role.PLANNER:
				return this.user?.PlannerId;
			case Role.TRAINER:
				return this.user?.TrainerId;
		}
	} */

	/**
	 * Get the role of the current user
	 *
	 * @returns The role as {@link Role}
	 */
	public getRole(): Role | undefined {
		return this.user?.role;
	}

	/**
	 * Get whether the current user is an admin
	 *
	 * @returns Whether the user is an admin or not
	 */
	public isAdmin(): boolean {
		return this.user?.role === Role.ADMIN;
	}

	/**
	 * A check whether the user is authenticated
	 * When true, it returns the user
	 *
	 * @returns Whether or not the user is authenticated
	 */
	public isAuthenticated(): IUser | undefined {
		if (this.user) {
			return this.user;
		}

		const user = this.storageService.getItem<IUser>(StorageKey.user);
		const token = this.storageService.getItem<string>(StorageKey.token);
		const refreshToken = this.storageService.getItem<string>(StorageKey.refreshToken);

		if (user && token && refreshToken) {
			this.setUser(user);
			this.setToken(token);
			this.setRefreshToken(refreshToken);
			return user;
		}

		return;
	}
}
