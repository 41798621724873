export enum Environment {
	Production = 'production',
	Staging = 'staging',
	Acceptance = 'acceptance',
	Local = 'local',
}

export enum StorageKey {
	user = 'user',
	token = 'token',
	refreshToken = 'refreshToken',
}

export enum Role {
	ADMIN = 'admin',
}

export enum NavigationDataKey {
	action = 'action',
}

export enum Action {
	UPDATE = 'update',
	CREATE = 'create',
	DELETE = 'delete',
	SELECT = 'select',
}

export enum FileType {
	Pdf = 'application/pdf',
	Image = 'image/*',
}

export enum Institution {
	GradeSchool = 'Lagere School',
	KinderGarden = 'Kleuter School',
	Buizegem = 'Buizegem',
}

export declare enum OrderDirection {
	asc = 'ASC NULLS LAST',
	desc = 'DESC NULLS LAST',
}
