/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { IChangePasswordCredentials, ILoginCredentials } from 'src/app/libs/interfaces';
import { IUser } from 'src/app/models/user.model';
import { Api } from '../api.provider';
import { Provider } from '../Provider';

@Injectable({
	providedIn: 'root',
})
export class UserProvider extends Provider<IUser> {
	protected override readonly url: string = '/users';

	constructor(protected readonly api: Api) {
		super();
	}

	public login(credentials: ILoginCredentials): Promise<{ access_token: string; refresh_token: string }> {
		return this.process(this.api.post<any>(`${this.url}/login`, credentials));
	}

	public updatePassword(credentials: IChangePasswordCredentials): Promise<void> {
		return this.process(this.api.post<any>(`${this.url}/update-password`, credentials));
	}

	public isActive(): Promise<boolean> {
		return this.process(this.api.get(`${this.url}/active`)) as Promise<boolean>;
	}

	public requestReset(credentials: { username: string }): Promise<IUser> {
		return this.process(this.api.post<any>(`${this.url}/reset`, credentials)) as Promise<IUser>;
	}

	public reset(credentials: { code: string; password: string }): Promise<IUser> {
		return this.process(this.api.post<any>(`${this.url}/reset/confirm`, credentials)) as Promise<IUser>;
	}
}
