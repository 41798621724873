import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpLoaderFactory } from './factories/http-loader.factory';
import { Interceptor } from './providers/interceptor';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			defaultLanguage: 'nl-BE',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		ToastModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'nl-BE' },
		{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
		HttpClient,
		MessageService,
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
	/**
	 * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
	 */
	static injector: Injector;

	constructor(private readonly injector: Injector) {
		AppModule.injector = this.injector;
	}
}
