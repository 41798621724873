import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public readonly version: string | undefined;

	constructor(
		private readonly primengConfig: PrimeNGConfig,
		private readonly translateService: TranslateService,
		private readonly meta: Meta
	) {
		this.version = this.meta.getTag('name=version')?.content;
	}

	ngOnInit() {
		this.translateService.use('nl-BE');
		this.translateService.get('PRIMENG').subscribe((res) => this.primengConfig.setTranslation(res));
		this.primengConfig.ripple = true;
	}
}
