import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./gui/pages/login-page/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'calendars',
		loadChildren: () => import('./gui/pages/calendars-page/calendars.module').then((m) => m.CalendarsPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'calendars/:id',
		loadChildren: () =>
			import('./gui/pages/calendar-details-page/calendar-details.module').then(
				(m) => m.CalendarDetailsPageModule
			),
		canActivate: [AuthGuard],
	},
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: 'login' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
