import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';
import { MessageService } from 'primeng/api';
import { IApiError } from '../libs/interfaces';

/**
 * The error service to handle errors and error messages across the application
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	private apiErrorIsShowing: boolean = false;

	constructor(private readonly injector: Injector) {}

	/**
	 * Shows the ErrorComponent in a popover containing the error message returned from the API
	 *
	 * @param _error The returned error
	 * @returns An empty promise to confirm the actual presentation of the popover
	 */
	public async showApiError(_error: any): Promise<void> {
		const getErrorMessage = (_error: any) => {
			if (isArray(_error?.error?.message || _error.message)) {
				return (_error?.error?.message || (_error.message as Array<string>)).join(', ');
			} else return this.injector.get(TranslateService).instant(_error.error.message || _error.message);
		};
		const error: IApiError = {
			status: _error.status,
			message: getErrorMessage(_error),
		};
		this.injector
			.get(MessageService)
			.add({ severity: 'error', summary: `Error: ${error.status}`, detail: error.message, sticky: true });
	}
}
