import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

/**
 * This function (factory) creates an Httploader that enables the app to use our own translation file
 * to translate strings in our application.
 *
 * @param {HttpClient} http - The HTTP client
 * @returns {TranslateHttpLoader} The configured TranslateHttpLoader instance
 */
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
	new TranslateHttpLoader(http, './assets/i18n/', '.json');
