import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ILoginCredentials } from '../libs/interfaces';
import { UserManager } from '../managers/user.manager';
import { IUser } from '../models/user.model';
import { UserProvider } from '../providers/api/user.provider';
import { StorageService } from './storage.service';

/**
 * The authentication service to handle authentication logic
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private authenticated!: boolean;

	constructor(
		private readonly userManager: UserManager,
		private readonly storageService: StorageService,
		private readonly userProvider: UserProvider,
		private readonly router: Router
	) {}

	/**
	 * Login, i.e. get a token from the api to authenticate future requests
	 *
	 *  When a user is logged in, the user object is added the the user manager
	 * and saved if the user wants to keep being logged in.
	 *
	 * @param credentials The login credentials as {@link ILoginCredentials}
	 */
	public async login(credentials: ILoginCredentials): Promise<void> {
		const data = await this.userProvider.login(credentials);
		const user: IUser = jwt_decode(data.access_token);
		this.userManager.setUser(user);
		this.userManager.setToken(data.access_token);
		this.userManager.setRefreshToken(data.refresh_token);

		if (credentials.keepMeLoggedIn) {
			this.userManager.saveUser();
			this.userManager.saveToken();
			this.userManager.saveRefreshToken();
		}

		this.authenticated = true;
		this.navigateToDefaultPage();
	}

	public navigateToDefaultPage(): void {
		this.router.navigate(['/calendars']);
	}

	/**
	 * Logout the current user
	 *
	 *  All user and application data is removed from session and persistent storage
	 */
	public logout(): void {
		this.userManager.removeUser();
		this.storageService.clearAll();
		this.authenticated = false;
		this.router.navigate(['/']);
	}

	/**
	 * A function used by the router authguard to make sure a user is authenticated when trying to access an url
	 *
	 * @returns Whether the user is authenticated
	 */
	public isAuthenticated(): Promise<boolean> {
		return new Promise((resolve) => {
			if (!this.userManager.isAuthenticated()) {
				this.authenticated = false;
				resolve(this.authenticated);
			}
			this.authenticated = true;
			resolve(this.authenticated);
		});
	}
}
