import { Injectable } from '@angular/core';
import { StorageKey } from '../libs/enums';

/**
 * The storage service to handle persistent storage of data as key-value pair
 *
 *  When the client is a web client, local storage is used. When the client is a native client, native storage is used.
 */
@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor() {}

	/**
	 * Get an item from persistent storage
	 *
	 * @param key The key linked to the data as {@link StorageKey}
	 * @returns The data linked to the key
	 */
	public getItem<Model>(key: StorageKey): Model {
		const item = localStorage.getItem(key);
		try {
			return JSON.parse(item as string);
		} catch (err) {
			throw new Error('Failed to get item from localStorage');
		}
	}

	/**
	 * Set an item in persistent storage as key-value pair
	 *
	 * @param key The key that has to be linked to the data as {@link StorageKey}
	 * @param data The data that needs to be stored
	 */
	public setItem(key: StorageKey, data: any): void {
		try {
			localStorage.setItem(key, JSON.stringify(data));
		} catch (err) {
			throw new Error('Failed to set item in localStorage');
		}
	}

	/**
	 * Remove an item from persistent storage
	 *
	 * @param key The key linked to the data as {@link StorageKey}
	 */
	public removeItem(key: StorageKey): void {
		try {
			localStorage.removeItem(key);
		} catch (err) {
			throw new Error('Failed to remove item from localStorage');
		}
	}

	/**
	 * Remove all data from persistent storage
	 */
	public clearAll(): void {
		localStorage.clear();
	}
}
